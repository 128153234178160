<template>
  <drag-test />
</template>

<script>
import DragTest from "../../components/TafelSystem";

export default {
  name: "AuftragsImport",
  components: {
    DragTest
  }
};
</script>
